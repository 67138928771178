<template>
	<div class="auth d-flex align-center justify-center">
		<v-card class="pt-15 auth__wrapper">
			<img src="@/assets/logo.svg" class="mx-auto d-block" alt="Личный кабинет представителя подразделения">
			<div class="auth__inputs-title mt-12 mb-4 text-center">Авторизация</div>

			<v-form @submit.prevent=""
					@keyup.native.enter="submit"
					v-model="isFormValid"
					class="auth__form mx-auto">
				<v-text-field v-model="form.login"
							  :rules="emailRules"
							  label="E-mail"
							  autocomplete="username"
							  required
							  maxlength="64"
				></v-text-field>
				<v-text-field v-model="form.password" class="mb-7"
							  :rules="passwordRules"
							  label="Пароль"
							  :type='passwordType'
							  @focus="passwordType='password'"
							  @blur="form.password?passwordType='password':passwordType='text'"
							  autocomplete="new-password"
							  required
							  maxlength="32"
				></v-text-field>
				<div v-if="error" class="mb-4">{{ error }}</div>
				<v-btn type="submit" color="primary" block :disabled="!isFormValid" @click="submit">Вход</v-btn>
			</v-form>
		</v-card>
	</div>
</template>

<script>
export default {
	name: "auth",
	data: () => ({
		error: null,
		form: {
			login: '',
			password: '',
		},
		passwordType: "text",
		emailRules: [
			v => !!v || 'Заполните поле E-mail',
			v => /.+@.+/.test(v) || 'E-mail введен некорректно',
		],
		passwordRules: [
			v => !!v || 'Введите пароль',
			//v => v.length >= 8 || 'Количество символов должно быть больше 8-ми',
		],
		isFormValid: false
	}),
	methods: {
		submit() {
			this.loading = true;
			this.error = null;
			this.$store.dispatch('post', {
				action: 'AuthController',
				params: this.form
			}).then((res) => {
				this.$router.push({name: "orders"});
			}).catch((error) => {
			    console.log("ERROR: ", error);
				this.error = error ? error : 'Не получилось авторизоваться';
			}).finally(() => {
				this.loading = false;
			});
		},
	}
}
</script>

<style lang="scss">
.auth {
	height: 100vh;
	width: 100%;
	background: $gray;

	&__wrapper {
		width: 640px;
		height: 540px;
		flex-wrap: wrap;

		img {
			width: 100px;
		}
	}

	&__inputs-title {
		width: 100%;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
	}

	&__form {
		width: 320px;
	}
}
</style>